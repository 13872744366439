import React, { useEffect } from "react"
import "./styles.css"
import Navbar from "../../components/Navbar"
import { ReactComponent as ArrowRight } from "../../assets/right.svg"
import { ReactComponent as RadioIcon } from "../../assets/radio.svg"
import { Link } from "react-router-dom"
import Footer from "../../components/Footer"
import MasterSearch from "../../components/MasterSearch"
import { useState } from "react"
import axios from "axios"
import { PiPlant } from "react-icons/pi"

const Home = () => {
	const [expandLocalities, setExpandLocalities] = useState()
	const [selectedCity, setSelectedCity] = useState()
	const [cities, setCities] = useState()
	const [featuredBusinesses, setFeaturedBusinesses] = useState()

	useEffect(() => {
		;(async () => {
			try {
				const response = await axios.get("/city/businesses")
				if (response.data) {
					setCities(response.data)
					onCityChange("0")
				}
			} catch (error) {}
		})()
	}, [])

	const cityDetails = cities?.find((i) => i._id === selectedCity)
	const onCityChange = async (city_id) => {
		try {
			setFeaturedBusinesses()
			setSelectedCity(city_id)
			const response = await axios.get(`/business/featured/${city_id}`)
			if (response.data)
				setFeaturedBusinesses(
					response.data?.map((i) => ({
						...i,
						categories: i?.categories?.map((i) => i.category),
						primary_cover: i?.primary_cover
							? `${axios.defaults.baseURL}/${i?.primary_cover}`
							: null,
					}))
				)
		} catch (error) {
			console.log(error)
		}
	}

	return (
		<div id="home">
			<div className="top-content">
				<div className="container flex">
					<Navbar hideLogo={true} />
					<div id="home-hero-content">
						<img src="/images/logo.png" alt="Sharanyu" />
						<p className="hero-text">Discover the best nursery in your city</p>
						<MasterSearch
							onCityChange={onCityChange}
							selectedCity={selectedCity}
							cities={[{ label: "All India", value: "0" }]?.concat(
								cities?.map((i) => ({
									label: i.name,
									value: i._id,
								})) || []
							)}
						/>
					</div>
				</div>
			</div>
			{featuredBusinesses?.length > 0 && (
				<div className="container">
					<div className="vertical-cards-wrapper ">
						{featuredBusinesses?.map((i) => (
							<Link to={`/business/${i.id}`}>
								<div className="vartical-card">
									{i.primary_cover ? (
										<img src={i.primary_cover} alt="Business Cover" />
									) : (
										<section className="flex h-full business-image-placeholder">
											<PiPlant />
										</section>
									)}
									<div>
										<div className="card-title font-m">
											<span>{i.title}</span>
										</div>
										<div className="font-s">
											<span>{cities?.find((_i) => _i._id === i.city)?.name}</span>
										</div>
										<div className="font-s">
											<span className="featured-locality">{i.locality}</span>
										</div>
										<div className="card-desc font-s">
											<span>{i.categories?.join(", ")}</span>
										</div>
									</div>
								</div>
							</Link>
						))}
					</div>
				</div>
			)}
			{cityDetails?.businesses?.length > 0 && (
				<div className="container">
					<div id="popular-locations">
						<h2 className="heading">Popular Nurseries in {cityDetails?.name}</h2>
						<div id="localities-wrapper">
							{cityDetails?.businesses
								?.slice(0, expandLocalities ? cityDetails?.businesses?.length : 8)
								?.map((i) => (
									<Link to={`/business/${i?.id}`} className="location-card">
										<div>
											<div className="font-m text-ellipse" style={{ "--lines": 1 }}>
												<span>{i.title}</span>
											</div>
											<div className="font-s business-locality">
												<small>{i.locality}</small>
											</div>
										</div>
										<div>
											<ArrowRight />
										</div>
									</Link>
								))}

							{cityDetails?.businesses?.length > 8 && (
								<div className="location-card">
									<button className="see-more" onClick={() => setExpandLocalities((_i) => !_i)}>
										{expandLocalities ? (
											<>
												<span className="font-m">see less</span>
												<ArrowRight style={{ rotate: "270deg" }} />
											</>
										) : (
											<>
												<span className="font-m">see more</span>
												<ArrowRight />
											</>
										)}
									</button>
								</div>
							)}
						</div>
					</div>
				</div>
			)}
			<div id="get-app">
				<div className="container flex">
					<div id="screenshots">
						<img src="/images/screenshots.png" alt="Screenshots" />
					</div>
					<div id="content">
						<h2 className="heading">Get the sharanyu app</h2>
						{/* <div className="desc font-m">
							<span>We will send you a link, open it on your phone to download the app</span>
						</div> */}
						{/* <div className="radios">
							<div>
								<input type="radio" id="link-on-email" hidden />
								<RadioIcon className="radio active" />
								<label htmlFor="link-on-email" className="font-m">
									Email
								</label>
							</div>
							<div>
								<input type="radio" id="link-on-phone" hidden />
								<RadioIcon className="radio" />
								<label htmlFor="link-on-phone" className="font-m">
									Phone
								</label>
							</div>
						</div> */}
						{/* <div className="flex">
							<input type="text" className="theme-input" placeholder="Email" style={{ flex: 1, width: "unset" }} />
							<button className="theme-btn primary">Share App Link</button>
						</div> */}
						<div>
							<div style={{ marginBottom: "6px" }}>
								<small>Download App From</small>
							</div>
							<div id="download-links">
								<Link
									to="https://play.google.com/store/apps/details?id=com.sharanyu&pcampaignid=web_share"
									className="download-link"
									target="blank"
								>
									<img
										src="/images/playstore.png"
										alt="Google Play Store"
										style={{ aspectRatio: 3.4 }}
										height={44}
									/>
								</Link>
								<Link to="" className="download-link" disabled={true}>
									<img
										src="/images/appstore.png"
										alt="Apple App Store"
										style={{ aspectRatio: 3.4 }}
										height={44}
									/>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default Home
